import axios from "axios";
import { reportError } from "@/utils/error-reporting";
import API from "@/modules/Project/classes/API";

export default class ProjectUnitTypeAPI extends API {
    async getAllUnitTypes({
        queries = {},
        page = 1,
        perPage = 15,
        include = "interactiveSitePlans"
    }) {
        try {
            let response = await axios.get(
                `${ProjectUnitTypeAPI.apiURL}/project-unit-types`,
                {
                    params: {
                        ...queries,
                        include: include,
                        page: page,
                        limit: perPage
                    }
                }
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Get All Proj U.Types`
            );
            throw error;
        }
    }
    async getUnitType({ id, include = "" }) {
        try {
            let response = await axios.get(
                `${ProjectUnitTypeAPI.apiURL}/project-unit-types/${id}?include=interactiveSitePlans,${include}`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Get Proj U.Type (#${id})`
            );
            throw error;
        }
    }
    async createUnitType(payload) {
        try {
            let response = await axios.post(
                `${ProjectUnitTypeAPI.apiURL}/project-unit-types`,
                payload
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Create Proj U.Type`
            );
            throw error;
        }
    }
    async updateUnitType({ id, payload }) {
        try {
            let response = await axios.put(
                `${ProjectUnitTypeAPI.apiURL}/project-unit-types/${id}`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Update Proj U.Type (#${id})`
            );
            throw error;
        }
    }
    async deleteUnitType(id) {
        try {
            const response = await axios.delete(
                `${ProjectUnitTypeAPI.apiURL}/project-unit-types/${id}`
            );

            return response;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Delete Proj U.Type (#${id})`
            );
            throw error;
        }
    }

    async createInteractiveSitePlan(unitTypeId, payload) {
        const URL = `${ProjectUnitTypeAPI.apiURL}/project-unit-types/${unitTypeId}/create-interactive-siteplan`;

        try {
            const response = await axios.post(URL, payload);
            return response;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Create I.SitePlan (#${unitTypeId})`
            );
            throw error;
        }
    }
    async updateInteractiveSitePlan({ unitTypeId, sitePlanId }, payload) {
        const URL = `${ProjectUnitTypeAPI.apiURL}/project-unit-types/${unitTypeId}/update-interactive-siteplan/${sitePlanId}`;

        try {
            const response = await axios.put(URL, payload);
            return response;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitTypeAPI.panelName}] Update I.SitePlan (#${sitePlanId})`
            );
            throw error;
        }
    }
}
