<template>
  <div v-if="!isEmpty(unitType)" class="unit-type-content">
    <div class="card p-3">
      <!-- Info -->
      <div class="row header">
        <div class="col-12">
          <p class="mb-1">
            <router-link
              :to="{
                name: 'ManageProjectsDetails',
                params: {
                  id: unitType.project.id
                }
              }"
            >
              {{ unitType.project.name }}
            </router-link>
          </p>
          <h2 class="unit-type-name mb-1">
            {{ unitType.name }}
          </h2>
          <p>{{ unitType.propertyType.name }}</p>
          <p v-if="unitType.completionDate" class="completion-date mt-1">
            <i class="fas fa-calendar mr-1"></i>{{ unitType.completionDate }}
          </p>
        </div>
        <div class="col-12 mt-2">
          <span
            v-for="phase in unitType.projectPhases"
            :key="phase.id"
            class="available-phases"
          >
            {{ phase.name }}
          </span>
        </div>
      </div>
      <div class="mb-4">
        <p class="booking-fee">
          <span class="font-bold">RM {{ parseInt(unitType.bookingFee) }} </span>
          Booking Fee
        </p>
      </div>
      <!-- Site Plans -->
      <div class="row mt-3 mb-4">
        <!-- Interactive -->
        <div v-if="canEdit" class="col-12 my-2">
          <h3 class="mb-2">
            <i class="fas fa-map mr-1"></i> Interactive Site Plan
          </h3>
          <div class="d-block mt-2">
            <a href="https://youtu.be/eI7TheCabsU" target="_blank">
              Video Guide
              <i class="fa-solid fa-arrow-up-right-from-square ml-1"></i>
            </a>
          </div>
          <fd-button
            class="bordered main my-2"
            @click="isSitePlanEditorShown = true"
          >
            {{ sitePlanActionLabel }}
          </fd-button>

          <hr />
        </div>

        <h3 class="mb-2">
          <i class="fas fa-images mr-1"></i> Static Site Plans
        </h3>

        <cool-light-box
          :items="unitTypeSiteMapImages"
          :index="unitTypeSiteMapIndex"
          @close="unitTypeSiteMapIndex = null"
          :slideshow="false"
        >
        </cool-light-box>
        <swiper class="swiper col-12 d-flex" :options="swiperOption">
          <swiper-slide
            v-for="(siteMap, imageIndex) in unitTypeSiteMapImages"
            :key="imageIndex"
            class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
          >
            <div class="image-container">
              <div
                class="image cursor-pointer"
                @click="unitTypeSiteMapIndex = imageIndex"
              >
                <img :src="siteMap" @error="$setAltPhoto" loading="lazy" />
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>

      <!-- VR Tour -->
      <div v-if="!isEmpty(unitType.vrLink)" class="video row mb-4">
        <h3 class="col-12 text-center mb-2">VR Tour</h3>
        <embed :src="unitType.vrLink" style="width: 600px; height: 400px" />
      </div>
    </div>

    <!-- ========================= FLOOR PLANS ========================= -->
    <manage-floor-plans
      :type="type"
      :projectId="$route.params.projectId"
      :unitTypeId="$route.params.id"
      :propertyTypeId="unitType.propertyType.id"
      :canAdd="canAdd"
      :canEdit="canEdit"
      :canDelete="canDelete"
    ></manage-floor-plans>
    <!-- Interactive Site Plan Editor -->
    <modal v-model="isSitePlanEditorShown" fullscreen>
      <site-plan-editor
        class="bg-white"
        :unitTypeId="unitType.id"
        :sitePlan="unitType.interactiveSitePlans[0]"
        :mode="sitePlanEditorMode"
        :role="type"
        @close="isSitePlanEditorShown = false"
        @created="init"
        @updated="init"
      >
        <template #title>
          <p class="fg-grey-700 mt-1">
            {{ unitType.project.name }}, {{ unitType.name }}
          </p>
        </template>
      </site-plan-editor>
    </modal>
  </div>
</template>

<script>
import { isEmpty } from "lodash";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitTypeAPI from "@/modules/Project/api/projectUnitType";

export default {
  components: {
    ManageFloorPlans: () =>
      import("@/modules/Project/components/FloorPlan/ManageFloorPlans"),
    SitePlanEditor: () =>
      import("@/modules/Project/components/InteractiveSitePlan/Editor")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      unitTypeAPI: new ProjectUnitTypeAPI(this.type),

      unitType: {},

      isSitePlanEditorShown: false,

      // Lightbox & Images
      unitTypeSiteMapIndex: null,
      unitTypeSiteMapImages: [],

      // swiper
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 5,
        freeMode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },

      isEmpty: isEmpty
    };
  },
  computed: {
    sitePlanActionLabel() {
      let action =
        this.unitType.interactiveSitePlans?.length < 1 ? "Create" : "Edit";
      return `${action} Interactive Site Plan`;
    },
    sitePlanEditorMode() {
      return this.unitType.interactiveSitePlans?.length < 1 ? "create" : "edit";
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);

      await this.getUnitType();
      this.initSiteMap();
      this.$store.commit("setIsLoading", false);
    },
    toEditPage() {
      // this.$router.push({
      //   name: "ProjectUnitTypeEdit",
      //   params: {
      //     id: this.$router.params.id
      //   }
      // });
    },
    initSiteMap() {
      let siteMapImages = this.unitType.siteMap.map((item) => {
        return this.$getFileURL(item);
      });
      this.unitTypeSiteMapImages = [];
      this.unitTypeSiteMapImages.push(...siteMapImages);
    },

    // ============================== API RELATED ==============================
    async getUnitType() {
      try {
        let data = await this.unitTypeAPI.getUnitType({
          id: this.$route.params.id,
          include: "projectPhases"
        });
        this.unitType = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.unit-type-details {
  .header {
    margin-bottom: 50px;

    .completion-date {
      width: fit-content;
      padding: 4px 12px;
      border-radius: 4px;
      border: solid 1px $color-1;
    }
    .available-phases {
      background: lighten($color-secondary, 45);
      color: $color-secondary;
      padding: 4px 12px;
      border-radius: 50em;
      font-size: 12px;
      margin: 0 4px;
    }
  }

  .booking-fee {
    background: $color-secondary;
    color: white;
    width: fit-content;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 5px;
  }

  .unit-type-content {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    @media #{$breakpoint-down-sm} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .video {
    padding: 40px 10px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }

  .image-container {
    @include flex(row, center, center);
    border: 1px solid #ddd;
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .image {
      display: block;
      width: 100%;
      img {
        @include image(cover);
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: white;
  }
}
</style>
